import img_1 from "../assets/img1.jpg";
import img_2 from "../assets/img2.jpg";
import img_3 from "../assets/img3.jpg";
import img_4 from "../assets/img4.jpg";
import img_5 from "../assets/img5.jpg";
import img_6 from "../assets/img6.jpg";

export const data = () => [
  {
    img: img_2,
    label: "Metal Production",
    info: `Unlike most waste management services we process, sort and clean your metal waste, 
            this waste is then sold into domestic mills and large foundries. `,
  },
  {
    img: img_3,
    label: "Metal Trading",
    info: `We buy and sell all ferrous and non-ferrous metal waste. Our customers 
            include manufacturers, metal fabricators, domestic mills, plumbers, electricians, 
            roofers and vehicle mechanics`,
  },
  {
    img: img_6,
    label: "Metal Appearance",
    info: `We improve the performance and appearance of metal parts. Whether you're looking for quality 
            anodizing specialities, coating, or another solution, our experienced team is here to help. `,
  },
  {
    img: img_1,
    label: "Plastic Crushing",
    info: `The recycling line can easily crushing, washing and drying wasted plastic products from beginning to finished 
        products. Waste and dirty agricultural films and waste packing material or rigid plastics can be treated step 
        by step by this machine.`,
  },
  {
    img: img_5,
    label: "Plastic Recycling",
    info: `We are plastic trading and recycling company, which is not only limited to buying and selling of plastic scrap and 
    off-grade materials but also converts plastic waste into premium quality recycled plastic granules. 
    Our team and CEO’s goal is to provide best quality product, we provide assurance of continuous supply and refined quality.`,
  },
  {
    img: img_4,
    label: "Plastic Manufacturing",
    info: `We are a leading manufacturer specializes in designing and 
    manufacturing all kinds of plastic machines with the experience engineers. Our products are widely recognized and trusted by domestic 
    and foreign customers and can meet continuously developing economic and social needs.`,
  },
];
