import React from "react";
import PropTypes from "prop-types";
import Heading from "../components/Heading";

const PrivacyPolicy = (props) => {
  return (
    <>
      <Heading id="privacy-policy" text="Privacy Policy" />
      <div className="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                At Tru-green Project, we are committed to safeguarding your
                privacy. This Privacy Policy outlines how we collect, use,
                disclose, and manage your information when you visit our website
                or engage with our services. Please take a moment to familiarize
                yourself with our privacy practices.
              </p>
              <h1 style={{ paddingTop: "2rem" }}>Information We Collect</h1>
              <p>
                We may collect personal information, such as your name, email
                address, phone number, and company details when you voluntarily
                provide it to us through forms on our website or during
                communication with our team.
              </p>
              <p>
                Our website may automatically collect certain information, such
                as your IP address, browser type, and device information. This
                data helps us analyze user trends and improve the functionality
                of our site.
              </p>
              <h1 style={{ paddingTop: "2rem" }}>
                How We Use Your Information
              </h1>
              <p>
                We use your personal information to deliver the services you
                request, such as web designing, development, and technical
                support. We may use your contact details to send relevant
                information about our services, updates, and newsletters. You
                can opt-out of these communications at any time. We utilize
                analytics tools to understand user behavior and improve the user
                experience on our website.
              </p>
              <h1 style={{ paddingTop: "2rem" }}>Data Security</h1>
              <p>
                We employ industry-standard security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, and destruction. Our team is committed to
                maintaining the confidentiality and integrity of your data.
              </p>
              <h1 style={{ paddingTop: "2rem" }}>Third-Party Disclosure</h1>
              <p>
                Tru-green Project does not sell, trade, or otherwise transfer
                your personal information to third parties without your consent.
                However, we may share your information with trusted third
                parties who assist us in providing our services, as long as they
                agree to keep your information confidential.
              </p>
              <h1 style={{ paddingTop: "2rem" }}>Your Rights</h1>
              <p>
                You have the right to access, correct, or delete your personal
                information. If you have any questions or concerns about the
                information we hold, please contact us at
                info@trugreensolutionsltd.co.uk.
              </p>
              <h1 style={{ paddingTop: "2rem" }}>
                Changes to this Privacy Policy
              </h1>
              <p>
                Tru-green Project reserves the right to update and modify this
                Privacy Policy at any time. The date of the last update will be
                prominently displayed at the top of this page. By using our
                website and services, you consent to the terms outlined in this
                Privacy Policy. If you have any questions or require further
                clarification, please contact us at
                info@trugreensolutionsltd.co.uk.
                <br />
                <br />
                <span
                  style={{
                    fontWeight: "light",
                    fontFamily: "var(--secondary_font)",
                  }}
                >
                  Thank you for choosing{" "}
                  <span style={{ color: "#41e249" }}>Tru-Green Project.</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PrivacyPolicy.propTypes = {};

export default PrivacyPolicy;
