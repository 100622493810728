import bgImg from "../assets/bgImg.jpg";
import down from "../assets/down.gif";

function Homepage() {
  return (
    <div id="/" className="homepage">
      <img src={bgImg} alt="bgImg" />
      <div className="homepage-overlay">
        <div className="outer">
          <h1>Welcome to</h1>
          <h1 className="company_name">TruGreen Solutions Ltd</h1>
          <h2>A Premier Global Exporter for Recyclables</h2>
          {/* <h2>Sustainable recycling solutions for your business</h2> */}
        </div>
      </div>

      <a href="#about">
        <img src={down} alt="down" />
      </a>
    </div>
  );
}

export default Homepage;
