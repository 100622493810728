import Heading from "../components/Heading";
import { data as getData } from "../lib/helper"


const About = () => {
    const data = getData();

    const renderAboutContent = (image, heading, text) => (
        <div className="about-container">
            <img src={image} alt="" />
            <div>
                <h1>{heading}</h1>
                <p>{text}</p>
            </div>
        </div>
    )

    console.log();
    return (
        <>
            <Heading id="about" text="About Us" />
            < div className="about" >
                <div className="about-wrapper">
                    {/* {renderAboutContent(data[0].img, data[0].label, data[0].info)} */}
                    {renderAboutContent(data[3].img, data[3].label, data[3].info)}
                    {/* {renderAboutContent(data[2].img, data[2].label, data[2].info)} */}
                    {renderAboutContent(data[4].img, data[4].label, data[4].info)}
                    {/* {renderAboutContent(data[1].img, data[1].label, data[1].info)} */}
                    {renderAboutContent(data[5].img, data[5].label, data[5].info)}
                </div>
            </div >
        </>
    )
}

export default About
